import { createStore, StoreProvider } from "easy-peasy";
import React, { lazy, Suspense } from "react";
import ReactDOM from "react-dom";
import Store, { StoreModel } from "store";
// import "swiper/css/bundle";
import 'swiper/swiper-bundle.css';
import Loader from "./components/Loader/Loader";
import "./index.scss";
import reportWebVitals from "./reportWebVitals";

const store = createStore<StoreModel>(Store);
const LazyApp = lazy(() => import("App"));

const app = (
    <React.StrictMode>
        <StoreProvider store={store}>
            <Suspense fallback={<Loader />}>
                <LazyApp />
            </Suspense>
        </StoreProvider>
    </React.StrictMode>
);

ReactDOM.render(app, document.getElementById("root"));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
