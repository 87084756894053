import { Action, action } from "easy-peasy";
import { emptyProxyObject, ProxyObjectType } from "utils/utils";

export interface File {
    file: File | any;
}

export interface FilesModel {
    file: File | ProxyObjectType;
    isOnlineAccountsVisible: boolean;
    setFile: Action<FilesModel, File | ProxyObjectType>;
    setOnlineAccountsVisibleHandle: Action<FilesModel, boolean>;
}

const FileStore: FilesModel = {
    file: emptyProxyObject,
    isOnlineAccountsVisible: false,
    setFile: action((state: any, data) => {
        state.file = data;
    }),
    setOnlineAccountsVisibleHandle: action((state: any, data) => {
        state.isOnlineAccountsVisible = data;
    }),
};

export default FileStore;
