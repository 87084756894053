import { Action, action } from "easy-peasy";
import { emptyProxyObject, ProxyObjectType } from "utils/utils";

export interface SignatureDetailsModel {
    signatureId: string;
    signatureUrl: string;
}

export interface SignatureModel {
    signatureDetails: SignatureDetailsModel | ProxyObjectType;
    signatureReceiverUrl: string;
    setSignatureDetails: Action<
        SignatureModel,
        SignatureDetailsModel | ProxyObjectType
    >;
    setSignatureReceiverUrl: Action<SignatureModel>;
}

const SignatureStore: SignatureModel = {
    signatureDetails: emptyProxyObject,
    signatureReceiverUrl: "",
    setSignatureDetails: action((state: any, data) => {
        state.signatureDetails = data;
    }),
    setSignatureReceiverUrl: action((state: any, payload: any) => {
        state.signatureReceiverUrl = payload;
    }),
};

export default SignatureStore;
