import { Action, action } from "easy-peasy";
import { emptyProxyObject, ProxyObjectType } from "utils/utils";

export interface DocLengthModel {
    docLengthDetails: Number | ProxyObjectType;
    docLengthBoolean: Boolean;
    setDocLengthBoolean: Action<DocLengthModel>;
    setDocLengthDetails: Action<DocLengthModel>;
}

const DocLengthStore: DocLengthModel = {
    docLengthDetails: emptyProxyObject,
    docLengthBoolean: true,
    setDocLengthDetails: action((state: any, data) => {
        state.docLengthDetails = data;
    }),
    setDocLengthBoolean: action((state: any, data) => {
        state.docLengthBoolean = data;
    }),
};

export default DocLengthStore;
