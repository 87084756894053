import { Action, action } from "easy-peasy";

export interface NotificationModel {
    notifications: Number;
    activeState: string;
    setNotifications: Action<NotificationModel>;
    setActiveState: Action<NotificationModel>;
}

const NotificationStore: NotificationModel = {
    notifications: 0,
    activeState: "all",
    setNotifications: action((state: any, data) => {
        state.notifications = data;
    }),
    setActiveState: action((state: any, data) => {
        state.activeState = data;
    }),
};

export default NotificationStore;
