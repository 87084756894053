import { Action, action } from "easy-peasy";

export interface TeamUserModel {
    createNewOrg: Boolean;
    addUserToOrg: Boolean;
    contactSuperAdmin: Boolean;
    selectedCreateOrg: Boolean;
    setCreateNewOrg: Action<TeamUserModel>;
    setAddUserToOrg: Action<TeamUserModel>;
    setContactSuperAdmin: Action<TeamUserModel>;
    setSelectedCreateOrg: Action<TeamUserModel>;
}

const TeamUserStore: TeamUserModel = {
    createNewOrg: false,
    addUserToOrg: false,
    contactSuperAdmin: false,
    selectedCreateOrg: false,

    setCreateNewOrg: action((state: any, data) => {
        state.createNewOrg = data;
    }),

    setAddUserToOrg: action((state: any, data) => {
        state.addUserToOrg = data;
    }),

    setContactSuperAdmin: action((state: any, data) => {
        state.contactSuperAdmin = data;
    }),

    setSelectedCreateOrg: action((state: any, data) => {
        state.setSelectedCreateOrg = data;
    }),
};

export default TeamUserStore;
