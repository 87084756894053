/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type CreateBulkSingingAuditsInput = {
    bulkSingingId: string;
    activity: number;
    message?: string | null;
    clientMutationId?: string | null;
};
export type createBulkSigningAuditMutationVariables = {
    input: CreateBulkSingingAuditsInput;
};
export type createBulkSigningAuditMutationResponse = {
    readonly createBulkSigningAudit: {
        readonly success: boolean | null;
    } | null;
};
export type createBulkSigningAuditMutation = {
    readonly response: createBulkSigningAuditMutationResponse;
    readonly variables: createBulkSigningAuditMutationVariables;
};



/*
mutation createBulkSigningAuditMutation(
  $input: CreateBulkSingingAuditsInput!
) {
  createBulkSigningAudit(input: $input) {
    success
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateBulkSingingAuditsPayload",
    "kind": "LinkedField",
    "name": "createBulkSigningAudit",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "success",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "createBulkSigningAuditMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "createBulkSigningAuditMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "cd5b44ad4f531ae46a944ef45bfeeca5",
    "id": null,
    "metadata": {},
    "name": "createBulkSigningAuditMutation",
    "operationKind": "mutation",
    "text": "mutation createBulkSigningAuditMutation(\n  $input: CreateBulkSingingAuditsInput!\n) {\n  createBulkSigningAudit(input: $input) {\n    success\n  }\n}\n"
  }
};
})();
(node as any).hash = 'a5bddfffd5fd683494626756395411c8';
export default node;
