import { Action, action } from "easy-peasy";

export interface ValuesForEditModel {
    valuesForEditDetails: any;
    setValuesForEditDetails: Action<ValuesForEditModel>;
}

const ValuesForEditStore: ValuesForEditModel = {
    valuesForEditDetails: [],
    setValuesForEditDetails: action((state: any, data) => {
        state.valuesForEditDetails = data;
    }),
};

export default ValuesForEditStore;
