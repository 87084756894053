import { Action, action, thunk, Thunk } from "easy-peasy";
import { emptyProxyObject, ProxyObjectType } from "utils/utils";
// import { query as auditQuery } from "../services/queries/auditQuery";

export interface SignatureAuditDetailsModel {
    signatureAuditDetails: any[];
}

export interface SignatureAuditModel {
    signatureAuditDetails: SignatureAuditDetailsModel | ProxyObjectType;
    setSignatureAuditDetails: Action<
        SignatureAuditModel,
        SignatureAuditDetailsModel | ProxyObjectType
    >;
    fetchSignatureAuditDetails: Thunk<SignatureAuditModel, any>;
}

const SignatureAuditStore: SignatureAuditModel = {
    signatureAuditDetails: emptyProxyObject,

    setSignatureAuditDetails: action((state: any, data) => {
        state.signatureAuditDetails = data;
    }),

    fetchSignatureAuditDetails: thunk(
        async (actions, { onSuccess, onFail }) => {
            // try {
            //     const signAudit: any = await await fetchQuery(
            //         auditQuery,
            //         {}
            //     ).toPromise();
            //     const tmpArr = [...signAudit.audit];
            //     tmpArr.forEach((element, i) => {
            //         const tmpObj = { ...element, showOptionModal: false };
            //         tmpArr[i] = tmpObj;
            //     });
            //     actions.setSignatureAuditDetails(tmpArr);
            //     onSuccess(tmpArr);
            // } catch (error: any) {
            //     onFail(error.message);
            //     console.warn(error.message);
            // }
        }
    ),
};

export default SignatureAuditStore;
