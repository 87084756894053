import { graphql } from "babel-plugin-relay/macro";

export const mutation = graphql`
    mutation createBulkSigningMutation($input: CreateBulkSigningInput!) {
        createBulkSigning(input: $input) {
            bulkSigning {
                id
            }
            presignedPostUrl
        }
    }
`;
