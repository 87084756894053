import { Action, action, Thunk, thunk } from "easy-peasy";
import { mutate } from "utils/relay";
import { emptyProxyObject, ProxyObjectType } from "utils/utils";

export interface ProfileModel {
    profileAccess: Thunk<ProfileModel, any>;
    signatureDetails: SignatureDetailsModel | ProxyObjectType;
    setSignatureDetails: Action<
        ProfileModel,
        SignatureDetailsModel | ProxyObjectType
    >;
}

export interface SignatureDetailsModel {
    signatureId: string;
    signatureUrl: string;
}

const ProfileStore: ProfileModel = {
    signatureDetails: emptyProxyObject,
    setSignatureDetails: action((state: any, data) => {
        state.signatureDetails = data;
    }),

    profileAccess: thunk(
        (actions, { mutation, input, onSuccess, onFailure }) => {
            mutate({
                mutation,
                input: input,
                onSuccess: function (data: any) {
                    onSuccess(data);
                },
                onFailure: function (messages: []) {
                    onFailure(messages);
                },
            });
        }
    ),
};

export default ProfileStore;
