const develop: string = "https://api.staging2.signedly.com";

const production: string = "";

const ngRok: string = "https://86fd-2401-4900-53e6-99d9-68f1-dcf0-b38-72f2.in.ngrok.io";

const qa: string = "https://devapi.signedly.com";

const dev: string = "https://devapi.signedly.com";

const uat: string = "https://uatapi.signedly.com";

const config: {
    develop: string;
    production: string;
    ngRok: string;
    qa: string;
    dev: string;
} = {
    production: production,
    develop: develop,
    ngRok: ngRok,
    qa: qa,
    dev: dev,
};

export default config.qa;
