import AuthStore, { AuthModel } from "store/auth";
import DocLengthStore, { DocLengthModel } from "store/docLength";
import NotificationStore, { NotificationModel } from "store/notifications";
import ProfileStore, { ProfileModel } from "store/profile";
import SignatureStore, { SignatureModel } from "store/signature";
import SignatureAuditStore, { SignatureAuditModel } from "store/signatureAudit";
import TeamUserStore, { TeamUserModel } from "store/teams";
import FileStore, { FilesModel } from "store/uploadFile";
import ValuesForEditStore, { ValuesForEditModel } from "store/valuesForEdit";

export interface StoreModel {
    auth: AuthModel;
    file: FilesModel;
    profile: ProfileModel;
    signature: SignatureModel;
    signatureAudit: SignatureAuditModel;
    docLength: DocLengthModel;
    notificationCount: NotificationModel;
    teams: TeamUserModel;
    valForEditBtn: ValuesForEditModel;
}

const Store: StoreModel = {
    auth: AuthStore,
    file: FileStore,
    profile: ProfileStore,
    signature: SignatureStore,
    signatureAudit: SignatureAuditStore,
    docLength: DocLengthStore,
    notificationCount: NotificationStore,
    teams: TeamUserStore,
    valForEditBtn: ValuesForEditStore,
};

export default Store;
