export enum RouteEnums {
    AUTH = "auth",
    LOGIN = "login",
    REGISTER = "register",
    RESET_PASSWORD1 = "reset-password-step1",
    RESET_PASSWORD2 = "reset-password-step2",
    VERIFY_OTP = "verify-otp",
    DASHBOARD = "documents",
    PROFILE = "profile",
    PLANS = "accounts-Settings/plans",
    CHANGE_PASSWORD = "change-password",
    DOCUMENT_CREATE = "upload",
    ADD_SIGNEE = "signees",
    PREPARE = "prepare",
    SEND_DOC = "send",
    DRIVE = "drive",
    DOCUMENT_TIMELINE = "document-timeline",
    RECEIVER = "receiver",
    BULKSEND_RECEIVER = "bulksend-receiver",
    BULKSEND_RECEIVER_API = "bulksend-receiver-api",
    DOCUMENT_VIEW = "view",
    SIGN_AUDIT = "profile/audit",
    TEMPLATE = "templates",
    ADD_ELEMENTS = "add-elements",
    PREVIEW = "preview",
    CLOUD = "cloud",
    WORKFLOW = "workflow",
    WORKFLOW_TIMELINE = "workflow-timeline",
    TEMPLATE_TIMELINE = "template-timeline",
    BULK_SEND = "bulk-sending",
    BULK_FILE_UPLOAD = "file-upload",
    BULK_SEND_DOC = "send-document",
    BULK_PICK_TEMP = "pick-template",
    BULK_TIMELINE = "timeline",
    BULK_CSV_PRE = "csv-preview",
    TEAMS = "teams",
    TEAMS_UPGRADE_PLAN = "teams-upgrade-plan",
    NOTIFICATIONS = "notifications",
    LOGOUT = "logout",
    ACCOUNTSANDSETTINGS = "accounts-Settings",
    API_RECEIVER = "api-receiver",
    CONTACTS = "contacts"
}
