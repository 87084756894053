import { graphql } from "babel-plugin-relay/macro";

export const mutation = graphql`
    mutation createBulkSigningAuditMutation(
        $input: CreateBulkSingingAuditsInput!
    ) {
        createBulkSigningAudit(input: $input) {
            success
        }
    }
`;
