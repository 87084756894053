import { toast } from "react-toastify";
import { mutation as createBulkSigningAuditMutation } from "../services/mutations/createBulkSigningAuditMutation";
import { mutation as createBulkSigningMutation } from "../services/mutations/createBulkSigningMutation";
import { mutation as createCsvMutation } from "../services/mutations/createCsvMutation";
import { mutation as csvUploadMutation } from "../services/mutations/csvUploadMutation";
import { mutation as maintainSigneeOrderMutation } from "../services/mutations/maintainSigneeOrderMutation";
import { mutate } from "./relay";
import { RouteEnums } from "./routes";

export interface ProxyObjectType { }
const emptyProxyObject: ProxyObjectType = new Proxy(
    {},
    {}
    // {
    //     get: () => {
    //         return emptyProxyObject;
    //     },
    // }
);
const isEmpty = (val: any) => {
    // Stolen From: https://stackoverflow.com/a/28953167
    /*
    test results
    --------------
    [] true, empty array
    {} true, empty object
    null true
    undefined true
    "" true, empty string
    '' true, empty string
    0 false, number
    true false, boolean
    false false, boolean
    Date false
    function false
    */
    if (val === emptyProxyObject) return true;
    if (val === undefined) return true;
    if (
        typeof val == "function" ||
        typeof val == "number" ||
        typeof val == "boolean" ||
        Object.prototype.toString.call(val) === "[object Date]"
    )
        return false;
    if (val == null || val.length === 0)
        // null or 0 length array
        return true;
    if (typeof val == "object") if (Object.keys(val).length === 0) return true;
    return false;
};
export const getValue = (val: any) => {
    return isEmpty(val) ? "-" : val;
};
const formatName = (name: any) => {
    if (!name) return "User Name";
    const splitName = name.split(" ");
    if (splitName.length === 1) return splitName[0];
    else if (splitName.length > 1)
        return `${splitName[0]} ${splitName[1].charAt(0)}.`;

    return "";
};

const capitalize = (s: string) => {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1);
};

const detectMobile = () => {
    const toMatch = [
        /Android/i,
        /webOS/i,
        /iPhone/i,
        /iPad/i,
        /iPod/i,
        /BlackBerry/i,
        /Windows Phone/i,
    ];

    return toMatch.some((toMatchItem) => {
        return window.navigator.userAgent.match(toMatchItem);
    });
};

const BULK_AUDIT_ACTIVITY = {
    CREATED: 1,
    PICKED: 2,
    UPLOADED: 3,
    DOWNLOADED: 4,
    SENT: 5,
};

const showToaster = (eventType: string, eventMess: string) => {
    switch (eventType.toLowerCase()) {
        case "success":
            toast.success(eventMess, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
                progress: undefined,
            });
            break;

        case "warn":
            toast.warn(eventMess, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
                progress: undefined,
            });
            break;

        case "info":
            toast.info(eventMess, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
                progress: undefined,
            });
            break;

        case "error":
            toast.error(eventMess, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
                progress: undefined,
            });
            break;

        default:
            toast(eventMess, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            break;
    }
};

const validatePassword = (input: string) => {
    const pattern =
        /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*#?&?^~])[A-Za-z\d@$!%*#?&]{8,16}$/;
    if (!pattern.test(String(input))) {
        return "Must Contain 8 to 16 Characters, One Uppercase, One Lowercase, One Number and one special case Character";
    } else {
        return "";
    }
};

const getMsgByBulkAuditActivity = (bulkActivity: number) => {
    switch (bulkActivity) {
        case 1:
            return "The Document has been created";
        case 2:
            return "The Template has been selected";
        case 3:
            return "The Template has been uploaded";
        case 4:
            return "The CSV has been downloaded";
        case 5:
            return "The Template has been sent";

        default:
            return "default MSG";
    }
};

const setUpdateBulkSignAuditActivity = (
    bulkSingingID: string,
    bulkActivity: number
) => {
    mutate({
        mutation: createBulkSigningAuditMutation,
        input: {
            input: {
                bulkSingingId: bulkSingingID,
                activity: bulkActivity,
                message: getMsgByBulkAuditActivity(bulkActivity),
            },
        },
        onSuccess: async (data: any) => {
            if (data.createBulkSigningAudit.success) {
            }
        },
        onFailure: (error: any) => {
            if (error) console.warn(error);
        },
    });
};

const createBulkSigningAndCsv = (
    ID: string,
    history: any,
    templateName: string,
    bulkActivity: number
) => {
    mutate({
        mutation: createBulkSigningMutation,
        input: { input: { id: ID } },
        onSuccess: async (createBulkData: any) => {
            if (createBulkData.createBulkSigning.bulkSigning.id) {
                mutate({
                    mutation: createCsvMutation,
                    input: {
                        input: {
                            id: createBulkData.createBulkSigning.bulkSigning.id,
                        },
                    },
                    onSuccess: async (data: any) => {
                        if (data) {
                            setUpdateBulkSignAuditActivity(
                                createBulkData.createBulkSigning.bulkSigning.id,
                                bulkActivity
                            );
                            history.push(
                                `/${RouteEnums.BULK_SEND}/${createBulkData.createBulkSigning.bulkSigning.id}/${RouteEnums.BULK_FILE_UPLOAD}`,
                                {
                                    csvDownloadURL:
                                        data.createCsv.presignedGetUrl,
                                    csvPostURL:
                                        createBulkData.createBulkSigning
                                            .presignedPostUrl,
                                    fileName: templateName,
                                }
                            );
                        }
                    },
                    onFailure: (error: any) => {
                        if (error) console.warn(error);
                    },
                });
            }
        },
        onFailure: (error: any) => {
            if (error) console.warn(error);
        },
    });
};

const downloadFileByURL = async (fileURL, fileName) => {
    // let pdfURL = `https://signly-documents.s3.ap-south-1.amazonaws.com/doconv/uploads/2/${
    //     props.uploadId.split("@")[1]
    // }-final.pdf`;
    await fetch(fileURL)
        .then((response) => response.blob())
        .then((b) => {
            let a = window.document.createElement("a");
            a.href = URL.createObjectURL(b);
            a.setAttribute("download", fileName);
            a.click();
        })
        .catch((e) => {
            console.warn(e);
        });
};

const postToS3 = async (url: string, file: File) => {
    return fetch(url, {
        method: "PUT",
        body: file,
    }).then((response) => { });
};

const validateEmail = (userEmail: string) => {
    const emailPattern =
        //eslint-disable-next-line
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!emailPattern.test(String(userEmail).toLowerCase())) {
        return "Enter valid email";
    } else {
        return "";
    }
};

const validateName = (userName: string) => {
    const namePattern = /^[a-zA-Z-,]+(\s{0,1}[a-zA-Z-, ])*$/;
    if (!namePattern.test(String(userName))) {
        return "Enter valid name";
    } else {
        return "";
    }
};

const onCsvFileUploadHandler = async (
    selectedFile: File,
    csvPostURLLink: string,
    bulkSignID: string,
    history: any
) => {
    if (selectedFile && selectedFile.type.includes("/csv")) {
        try {
            // setShowLoader(true);
            await postToS3(csvPostURLLink, selectedFile);
            mutate({
                mutation: csvUploadMutation,
                input: {
                    input: {
                        id: bulkSignID,
                    },
                },
                onSuccess: async (data: any) => {
                    if (data) {
                        // setShowLoader(false);
                        history.push(
                            `/${RouteEnums.BULK_SEND}/${bulkSignID}/${RouteEnums.BULK_CSV_PRE}`,
                            {
                                csvData: data.csvUpload.result.csv_data,
                                errors: data.csvUpload.result.error,
                                csvPostURLLink: csvPostURLLink,
                            }
                        );
                    }
                },
                onFailure: (error: any) => {
                    // setShowLoader(false);
                    if (error) {
                        console.warn(error);
                        showToaster("error", error[0]);
                    }
                },
            });
        } catch (error) {
            console.warn(error);
            // setShowLoader(false);
        }
    } else {
        console.warn("upload CSV file only");
        showToaster("error", "Upload CSV file only");
    }
};

const convertDate = (date) => {
    const fullDate = new Date(date);
    const month = fullDate.toLocaleString("en-US", { month: "short" });
    const year = fullDate.getFullYear();
    const day = fullDate.toLocaleString("en-US", { day: "2-digit" });
    return `${day} ${month} ${year}`;
};

const getURLFromPostURL = (URLObjStr: string) => {
    const imageOptions = JSON.parse(URLObjStr.replace(/'/g, '"'));
    return (
        "http://s3-ap-south-1.amazonaws.com/signly-documents/" +
        imageOptions.fields.key
    );
};

const statusMessages = (activity: number, notSingleSignee = true) => {
    if (activity === 1) {
        return "Document was Created";
    } else if (activity === 2) {
        return "Document was Sent";
    } else if (activity === 3) {
        return "Document was Viewed";
    } else if (activity === 4) {
        return "Document was Signed";
    } else if (activity === 5) {
        if (notSingleSignee) {
            return "Document is Completed";
        } else {
            return "Document is Completed and Signed by all signees";
        }
    } else if (activity === 6) {
        return "Document was Rejected";
    } else if (activity === 9) {
        return "Reassign Signatory";
    } else if (activity === 10) {
        return "Document was Archived";
    } else if (activity === 11) {
        return "Document was Unarchived";
    } else if (activity === 13) {
        return "Document was approved"
    } else if (activity === 14) {
        return "Document was expired"
    } else if (activity === 15) {
        return "Terms Accepted"
    }
};

const maintainSigneeOrderHandler = (
    checked: any,
    uploadId,
    showHideLoader,
    routeToPrepare?
) => {
    const formData = {
        isMaintainSigneeOrder: checked,
        documentId: uploadId,
    };
    showHideLoader(true);
    mutate({
        mutation: maintainSigneeOrderMutation,
        input: { input: formData },
        onSuccess: async (data: any) => {
            if (data.maintainSigneeOrder.success) {
                showHideLoader(false);
                if (routeToPrepare) routeToPrepare();
            }
        },
        onFailure: (error: any) => {
            showHideLoader(false);
        },
    });
};

const isFileNameValid = (fileName: string) => {
    const filename = fileName.trim();
    const re1 = /^[a-zA-Z0-9_-][a-zA-Z0-9 _-]*[a-zA-Z0-9_-]+$/;
    const re2 =
        /^[a-zA-Z0-9_-][a-zA-Z0-9 _-]*[a-zA-Z0-9_-]+(.doc|.docx|.pdf|.txt|.png|.jpeg|.jpg)$/;
    return re1.test(filename) || re2.test(filename) ? true : false;
};

const DateFormat = {
    1: { display: "DD/MM/YYYY", dp: "dd/MM/yyyy" },
    2: { display: "MM/DD/YYYY", dp: "MM/dd/yyyy" },
    3: { display: "DD MMM YYYY", dp: "dd MMM yyyy" },
};

const signeesColor = [
    "#e8dff5",
    "#fce1e4",
    "#fcf4dd",
    "#ddedea",
    "#daeaf6",
    "#ffc09f",
    "#ffee93",
    "#adf7b6",
    "#a0ced9",
    "#fcf5c7",
    "#809bce",
    "#95b8d1",
    "#eac4d5",
    "#daeaf6",
    "#7ec4cf",
    "#d1cfe2",
    "#adf7b6",
    "#ffee93",
    "#84dcc6",
    "#ff686b",
];

const LightenDarkenColor = (col, amt) => {
    var usePound = false;

    if (col[0] === "#") {
        col = col.slice(1);
        usePound = true;
    }

    var num = parseInt(col, 16);

    var r = (num >> 16) + amt;

    if (r > 255) r = 255;
    else if (r < 0) r = 0;

    var b = ((num >> 8) & 0x00ff) + amt;

    if (b > 255) b = 255;
    else if (b < 0) b = 0;

    var g = (num & 0x0000ff) + amt;

    if (g > 255) g = 255;
    else if (g < 0) g = 0;

    return (usePound ? "#" : "") + (g | (b << 8) | (r << 16)).toString(16);
};

function addAlpha(color: string, opacity: number): string {
    // coerce values so ti is between 0 and 1.
    const _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
    return color + _opacity.toString(16).toUpperCase();
}

const frontendUrl = {
    local: "http://localhost:3000",
    qa: "https://uat.signedly.com/",
    dev: "https://dev.signedly.com",
};

const debounce = (cb, d) => {
    let timer;
    return function (...args) {
        if (timer) clearTimeout(timer);
        timer = setTimeout(() => {
            cb(...args);
        }, d);
    };
};

export {
    isEmpty,
    emptyProxyObject,
    formatName,
    capitalize,
    detectMobile,
    // GetIPAndCounAPI,
    showToaster,
    validatePassword,
    createBulkSigningAndCsv,
    downloadFileByURL,
    postToS3,
    validateEmail,
    validateName,
    onCsvFileUploadHandler,
    BULK_AUDIT_ACTIVITY,
    setUpdateBulkSignAuditActivity,
    convertDate,
    getMsgByBulkAuditActivity,
    getURLFromPostURL,
    statusMessages,
    maintainSigneeOrderHandler,
    isFileNameValid,
    DateFormat,
    LightenDarkenColor,
    signeesColor,
    addAlpha,
    frontendUrl,
    debounce
};
