// DON'T EDIT. THIS FILE IS GENERATED BY ./manage.py frontend_export
// CHANGE MADES MANUALLY TO THIS FILE WILL BE LOST
// backend/contrib/management/commands/frontend_export.py

const CHOICES = {
    AuditKind: {
        ADDED: 2,
        CHOICES: {
            "1": "Viewed",
            "2": "Added",
            "3": "Submitted",
        },
        CHOICE_LIST: [
            {
                label: "Viewed",
                value: 1,
            },
            {
                label: "Added",
                value: 2,
            },
            {
                label: "Submitted",
                value: 3,
            },
        ],
        SUBMITTED: 3,
        VIEWED: 1,
    },
    DocumentStatus: {
        CHOICES: {
            "1": "Error",
            "2": "Converting",
            "3": "Creating Pages",
            "4": "Ready",
            "5": "Sent",
            "6": "Signed",
            "7": "Completed",
        },
        CHOICE_LIST: [
            {
                label: "Error",
                value: 1,
            },
            {
                label: "Converting",
                value: 2,
            },
            {
                label: "Creating Pages",
                value: 3,
            },
            {
                label: "Ready",
                value: 4,
            },
            {
                label: "Sent",
                value: 5,
            },
            {
                label: "Signed",
                value: 6,
            },
            {
                label: "Completed",
                value: 7,
            },
        ],
        CONVERTING: 2,
        CREATING_PAGES: 3,
        ERROR: 1,
        COMPLETED: 7,
        READY: 4,
        SENT: 5,
        SIGNED: 6,
    },
    ElementKind: {
        CHECKBOX: 8,
        CHOICES: {
            "1": "Text",
            "2": "Signature",
            "3": "Date",
            "8": "Checkbox",
        },
        CHOICE_LIST: [
            {
                label: "Text",
                value: 1,
            },
            {
                label: "Signature",
                value: 2,
            },
            {
                label: "Date",
                value: 3,
            },
            {
                label: "Checkbox",
                value: 8,
            },
        ],
        DATE: 3,
        SIGNATURE: 2,
        TEXT: 1,
    },
    SigneeStatus: {
        CHOICES: {
            "1": "Waiting",
            "2": "Invited",
            "3": "Signed",
        },
        CHOICE_LIST: [
            {
                label: "Waiting",
                value: 1,
            },
            {
                label: "Invited",
                value: 2,
            },
            {
                label: "Signed",
                value: 3,
            },
        ],
        INVITED: 2,
        SIGNED: 3,
        WAITING: 1,
    },
    UploadKind: {
        CHOICES: {
            "1": "Profile Picture",
        },
        CHOICE_LIST: [
            {
                label: "Profile Picture",
                value: 1,
            },
        ],
        DOCUMENT: 1,
        PROFILE_PICTURE: 1,
    },
    UploadStatus: {
        CHOICES: {
            "1": "Uploading",
            "2": "Uploaded",
            "3": "Processing",
            "4": "Processed",
            "5": "Error",
        },
        CHOICE_LIST: [
            {
                label: "Uploading",
                value: 1,
            },
            {
                label: "Uploaded",
                value: 2,
            },
            {
                label: "Processing",
                value: 3,
            },
            {
                label: "Processed",
                value: 4,
            },
            {
                label: "Error",
                value: 5,
            },
        ],
        ERROR: 5,
        PROCESSED: 4,
        PROCESSING: 3,
        UPLOADED: 2,
        UPLOADING: 1,
    },
    UserKind: {
        ADMIN: 1,
        CHOICES: {
            "1": "Admin",
            "2": "Client",
        },
        CHOICE_LIST: [
            {
                label: "Admin",
                value: 1,
            },
            {
                label: "Client",
                value: 2,
            },
        ],
        CLIENT: 2,
    },
};

export default CHOICES;
